import React from "react";
import { useSelector } from "react-redux";

// import images
import lightImg from "../../assets/images/404/light.webp";
import darkImg from "../../assets/images/404/dark.webp";
// import components
import HBox from "components/HBox";
import Header from "components/Header";
import HImage from "components/HImage";
import { Helmet } from "react-helmet";
const Index = () => {
  const { theme } = useSelector((state) => state.appConfig);
  return (
    <>
      <Helmet>
        <title>Winbee io | Page 404</title>
        <meta property="og:title" content="Winbee io | Page 404" />
        <meta
          name="description"
          content="Home of crypto trading bot Run automated trading strategies with confidence and invest alongside the world's top quantitative traders."
        />
        <meta
          property="og:description"
          content="Home of crypto trading bot Run automated trading strategies with confidence and invest alongside the world's top quantitative traders."
        />
      </Helmet>
      <HBox className="flex flex-col justify-center items-center h-screen">
        <HImage
          src={theme === "dark" ? darkImg : lightImg}
          alt={"404 error"}
          className="w-2/4 xl:w-1/4 mx-auto mb-10"
        />
        <Header as="h2">Sorry this page not found</Header>
      </HBox>
    </>
  );
};

export default Index;
