import HBox from "components/HBox";
import React from "react";

const Index = ({ className = "h-[700px]" }) => {
  return (
    <HBox className={`absolute ${className} flex items-center top-2/4 -translate-x-2/4  left-2/4`}>
      <HBox className="circle w-[100px] h-[100px] animate-spin  rounded-full border-[6px] border-t-primary border-r-primary border-b-primary dark:border-t-primary-dark dark:border-r-primary-dark dark:border-b-primary-dark"></HBox>
    </HBox>
  );
};

export default Index;
