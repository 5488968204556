import React, { useLayoutEffect } from "react";
import HBox from "components/HBox";
import HCard from "components/HCard";

import { Navigate, Outlet, useHref } from "react-router-dom";
import { useSelector } from "react-redux";

export const authRoutes = ["login", "sign-up", "reset-password", "email-verify"];
const Index = () => {
  const user = JSON.parse(window.localStorage.getItem("user"));
  const href = useHref().slice(1);
  const { exchangeId } = useSelector((state) => state.appConfig);
  const { exchanges } = useSelector((state) => state.exchange);

  // useLayoutEffect(() => {
  //   if (!user && !authRoutes.includes(href)) {
  //     localStorage.backLink = window.location.href;
  //   }
  // }, [user]);

  // ref of sidebar
  if (authRoutes.includes(href)) {
    return user ? <Navigate to="/positions" /> : <Outlet />;
  }

  return user ? (
    <HBox className={`mt-20 relative transition-all left-[80px] w-[calc(100%_-_80px)]`}>
      {exchanges && exchanges.find((exchange) => exchange.id === exchangeId)?.valid === 0 && (
        <HBox className="p-4 pb-0">
          <HCard className="text-danger">
            This api is invalid please try another one, all features will not work correctly
          </HCard>
        </HBox>
      )}
      <Outlet />
    </HBox>
  ) : (
    <Navigate to="/login" />
  );
};

export default Index;
