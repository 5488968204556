import React, { forwardRef, memo } from "react";

const Index = forwardRef(
  (
    { type = "button", varient = "fill", color = "primary", className, onClick, children, ...rest },
    ref
  ) => {
    const btnVarient = {
      fill: {
        primary: "bg-primary text-secondry-font dark:bg-primary-dark",
        secondry: "bg-secondry text-secondry-font dark:bg-secondry-dark",
        success: "bg-success text-secondry-font",
        danger: "bg-danger text-secondry-font",
        light: "bg-third text-primary-font",
      },
      outlined: {
        primary:
          "border border-primary dark:border-primary-dark text-primary dark:text-primary-dark hover:bg-primary dark:hover:bg-primary-dark hover:text-secondry-font dark:hover:text-secondry-font",
        secondry:
          "border border-secondry dark:border-secondry-font text-secondry dark:text-secondry-font hover:bg-secondry dark:hover:bg-secondry-font hover:text-secondry-font dark:hover:text-secondry-dark",

        success:
          "border border-success text-success  hover:bg-success dark:hover:bg-success hover:text-secondry-font ",
        danger: "border border-danger  text-danger  hover:bg-danger  hover:text-secondry-font",
        light: "border border-third text-light-font hover:bg-third hover:text-primary-font",
        default: "border border-primary-border dark:border-primary-border-dark",
      },
      transparent: {
        primary: "bg-transparent text-primary dark:text-primary-dark",
        secondry: "bg-transparent text-secondry dark:text-secondry-font",
        success: "bg-transparent text-success",
        danger: "bg-transparent text-danger",
        light: "bg-transparent text-light-font",
      },
    };
    return (
      <button
        ref={ref}
        type={type}
        className={`${btnVarient[varient][color]} outline-none hover:opacity-90 transition-all p-2 rounded-md flex ${className} justify-center items-center `}
        onClick={onClick}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

export default memo(Index);
