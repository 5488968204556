import React, { useEffect, Suspense } from "react";
import * as Sentry from "@sentry/react";

// import styles
import "./index.css";

// import services
import routes from "routes";
import {
  Route,
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { storeAuth } from "redux/slices/auth";
import { storeTheme } from "redux/slices/appConfig";
import { getProfileImage } from "utils/functions";

// import components
import ProtectedRoute from "./components/ProtectedRoute";
import NoPage from "./pages/404";
import Navbar from "components/Navbar";
import Loading from "components/Loading";
import ZoholQ from "components/ZoholQ";
import { Toaster } from "react-hot-toast";

Sentry.init({
  dsn: "https://183dcf7dbee9255f02b9b41e72679bc3@o4504659680231424.ingest.sentry.io/4506483676741632",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://app.winbee.io"],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const App = () => {
  // save auth to state managment
  const dispatch = useDispatch();

  const userData = JSON.parse(localStorage.getItem("user"));
  const appTheme = localStorage.getItem("winbee-theme");
  const { user } = useSelector((state) => state.auth);
  const { theme } = useSelector((state) => state.appConfig);
  // save user info in auth slice
  useEffect(() => {
    if (userData) {
      if (appTheme === "dark") {
        document.documentElement.classList.add(appTheme);
      }
      dispatch(storeTheme(appTheme));
      dispatch(storeAuth(userData));
      getProfileImage(dispatch);
    }
  }, []);

  return (
    <div className="winbee-app text-primary-font dark:text-light-font">
      <ZoholQ />
      <Toaster
        toastOptions={{
          success: {
            style: {
              backgroundColor: theme === "dark" ? "var(--d-third-color)" : "",
              color: theme === "dark" ? "var(--secondry-font)" : "",
            },
          },
        }}
      />
      {user && <Navbar theme={theme} routes={routes(user)} />}
      <SentryRoutes>
        {routes(user).map((route, index) => (
          <Route
            key={index}
            element={
              <Suspense fallback={<Loading />}>
                <ProtectedRoute />
              </Suspense>
            }
          >
            <Route {...route} />
          </Route>
        ))}
        <Route path="*" element={<NoPage />} />
      </SentryRoutes>
    </div>
  );
};

export default Sentry.withProfiler(App);
