import { configureStore } from "@reduxjs/toolkit";
import auth from "./slices/auth";
import exchange from "./slices/exchanges";
import appConfig from "./slices/appConfig";
import stateManage from "./slices/stateManage";

export const store = configureStore({
  reducer: {
    auth,
    exchange,
    appConfig,
    stateManage,
  },
});
