import HBox from "components/HBox";
import React, { memo } from "react";

const Index = ({ src, alt, className }) => {
  return (
    <HBox>
      <img className={`${className}`} src={src} alt={alt} loading="lazy" />
    </HBox>
  );
};

export default memo(Index);
