// import utils
import { icon } from "utils/icons";
import { lazy } from "react";

// import components
const Login = lazy(() => import("pages/auth/Login"));
const ResetPassword = lazy(() => import("pages/auth/ResetPassword"));
const SignUp = lazy(() => import("pages/auth/SignUp"));
const EmailVerify = lazy(() => import("pages/auth/EmailVerify"));
const Dashboard = lazy(() => import("pages/dashboard"));
const Position = lazy(() => import("pages/position"));
const TradingTerminal = lazy(() => import("pages/terminal"));
const SwapTerminal = lazy(() => import("pages/swap"));
const Strategies = lazy(() => import("pages/dca"));
const CreateExchange = lazy(() => import("pages/exchange/CreateExchange"));
const EditExchange = lazy(() => import("pages/exchange/EditExchange"));
const DcaBots = lazy(() => import("pages/dca/DcaBots"));
const FollowSettings = lazy(() => import("pages/signal/FollowSettings"));
const ProviderDetails = lazy(() => import("pages/signal/details/index"));
const GridBots = lazy(() => import("pages/gridbots"));
const SignalProvider = lazy(() => import("pages/signal"));
const GlobalSettings = lazy(() => import("pages/globalSettings"));
const MyExchange = lazy(() => import("pages/exchange"));
const Profile = lazy(() => import("pages/profile"));
const User = lazy(() => import("pages/user"));
const OpenOrders = lazy(() => import("pages/user/OpenOrders"));
const OrderDetails = lazy(() => import("pages/position/OrderDetails"));
const Membeship = lazy(() => import("pages/membership"));
const Screener = lazy(() => import("pages/screener"));
const HeatMap = lazy(() => import("pages/heatmap"));

// admin pages import
const AdminDashboard = lazy(() => import("pages/admin/dashboard"));
const Payments = lazy(() => import("pages/admin/payments"));
const Users = lazy(() => import("pages/admin/users"));
const Monitor = lazy(() => import("pages/admin/monitor"));
const Withdrawals = lazy(() => import("pages/admin/withdrawals"));
const FollowSetting = lazy(() => import("pages/admin/users/settings/FollowSetting"));
const RecoverExchange = lazy(() => import("pages/exchange/RecoverExchange"));

// routes
const routes_admin = [
  {
    type: ["route", "collapse"],
    name: "Dashboard",
    key: "dashboard",
    icon: icon.dashboard,
    path: "/",
    element: <AdminDashboard />,
  },
  {
    type: ["route", "collapse"],
    name: "Account Monitor",
    key: "account_monitor",
    icon: icon.eye,
    path: "/monitor",
    element: <Monitor />,
  },
  {
    type: ["route", "collapse"],
    name: "Users",
    key: "users",
    icon: icon.user,
    path: "/users",
    element: <Users />,
  },
  {
    type: ["route"],
    name: "Users",
    key: "follow-settings",
    icon: icon.setting,
    path: "/users/:exchangeId/follow-settings",
    element: <FollowSetting />,
  },
  {
    type: ["route", "collapse"],
    name: "Payments",
    key: "payments",
    icon: icon.usd,
    path: "/payments",
    element: <Payments />,
  },
  {
    type: ["route", "collapse"],
    name: "Withdrawals",
    key: "withdrawals",
    icon: icon.withdrawal,
    path: "/withdrawals",
    element: <Withdrawals />,
  },
];
const routes_user = [
  {
    type: ["route", "collapse"],
    name: "Dashboard",
    key: "dashboard",
    icon: icon.dashboard,
    path: "/",
    element: <Dashboard />,
  },
  {
    type: ["route", "collapse"],
    name: "My Wallet",
    key: "my-wallet",
    path: "/user",
    icon: icon.usd,
    element: <User />,
  },
  {
    type: ["route"],
    name: "Open Orders",
    key: "open-orders",
    path: "/user/open-orders",
    element: <OpenOrders />,
  },
  {
    type: ["route", "collapse"],
    name: "Positions",
    key: "positions",
    icon: icon.control,
    path: "/positions",
    element: <Position />,
    index: true,
  },

  {
    type: ["route"],
    name: "Order Details",
    key: "order details",
    path: "/positions/:orderId",
    element: <OrderDetails />,
  },
  {
    type: ["route", "collapse"],
    name: "Trading Terminal",
    key: "trading-terminal",
    icon: icon.stock,
    path: "/trading-terminal",
    element: <TradingTerminal />,
  },
  {
    type: ["route"],
    name: "Edit Trading Terminal",
    key: "edit-trading-terminal",
    icon: icon.stock,
    path: "/trading-terminal/:strategyId",
    element: <TradingTerminal />,
  },
  {
    type: ["route", "collapse"],
    name: "Swap Terminal",
    key: "swap-terminal",
    icon: icon.interactior,
    path: "/swap-terminal",
    element: <SwapTerminal />,
  },

  {
    type: ["route", "collapse"],
    name: "Dca Bots",
    key: "dca",
    icon: icon.robot,
    path: "/strategies",
    element: <Strategies />,
  },
  {
    type: ["route"],
    name: "Dca Bots",
    key: "dca",
    icon: icon.robot,
    path: "/strategies/dca-bots",
    element: <DcaBots />,
  },
  {
    type: ["route"],
    name: "Dca Bots",
    key: "dca",
    icon: icon.robot,
    path: "/strategies/dca-bots/:strategyId",
    element: <DcaBots />,
  },

  // {
  //   type: ["route", "collapse"],
  //   name: "Grid Bots",
  //   key: "grid",
  //   icon: icon.grid,
  //   path: "/grid-bots",
  //   element: <GridBots />,
  // },
  // {
  //   type: ["route"],
  //   name: "Edit Grid Bots",
  //   key: "edit-grid",
  //   icon: icon.grid,
  //   path: "/grid-bots/:strategyId",
  //   element: <GridBots />,
  // },
  {
    type: ["route", "collapse"],
    name: "Signal Provider",
    key: "signal-provider",
    icon: icon.signal,
    path: "/signals",
    element: <SignalProvider />,
  },
  {
    type: ["route"],
    name: "view-provider",
    key: "view-provider",
    icon: icon.signal,
    path: "/signals/:providerId",
    element: <ProviderDetails />,
  },
  {
    type: ["route"],
    name: "follow-settings",
    key: "follow-settings",
    icon: icon.signal,
    path: "/signals/follow/:followId",
    element: <FollowSettings />,
  },
  {
    type: ["route", "collapse"],
    name: "My Exchange",
    key: "my-exchange",
    icon: icon.exchange,
    path: "/exchange",
    element: <MyExchange />,
  },
  {
    type: ["route"],
    name: "Create Exchange",
    key: "create-exchange",
    icon: icon.exchange,
    path: "/exchange/create",
    element: <CreateExchange />,
  },
  {
    type: ["route"],
    name: "Edit Exchange",
    key: "edit-exchange",
    icon: icon.exchange,
    path: "/exchange/:exchangeId",
    element: <EditExchange />,
  },
  {
    type: ["route", "collapse"],
    name: "My Profile",

    key: "profile",
    icon: icon.profile,
    path: "/profile",
    element: <Profile />,
  },
  {
    type: ["route", "collapse"],
    name: "Crypto Screener",

    key: "screener",
    icon: icon.eye,
    path: "/screener",
    element: <Screener />,
  },
  {
    type: ["route", "collapse"],
    name: "Crypto Heatmap",

    key: "heatmap",
    icon: icon.microsoft,
    path: "/heatmap",
    element: <HeatMap />,
  },

  {
    type: ["route"],
    name: "Login",
    key: "login",
    path: "/login",
    element: <Login />,
  },
  {
    type: ["route"],
    name: "Sign Up",
    key: "SignUp",
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    type: ["route"],
    name: "Reset Password",
    key: "Reset",
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    type: ["route"],
    name: "Email Verify",
    key: "EmailVerify",
    path: "/email-verify",
    element: <EmailVerify />,
  },
  {
    type: ["route", "collapse"],
    name: "Membeship",
    key: "membership",
    icon: icon.membership,
    path: "/membership",
    element: <Membeship />,
  },
  {
    type: ["route"],
    name: "Recover Exchange",
    key: "recover-exchange",
    icon: icon.exchange,
    path: "/exchange/recover",
    element: <RecoverExchange />,
  },
];

// check user role function
const routes = (userInfo) => {
  for (let i = 0; i < userInfo?.roles?.length; i++) {
    if (userInfo.roles[i] == "ROLE_ADMIN") return routes_admin;
  }
  return routes_user;
};
export default routes;
