import React, { memo } from "react";

const Index = React.forwardRef(({ children, ...rest }, ref) => {
  return (
    <div {...rest} ref={ref}>
      {children}
    </div>
  );
});

export default memo(Index);
