import React, { useCallback, useState, memo, useEffect, useRef } from "react";

// import services
import authServices from "utils/services/auth.services";
import { Link, useHref } from "react-router-dom";
import { Tooltip } from "@chakra-ui/react";
import { icon } from "utils/icons";
import { useDispatch, useSelector } from "react-redux";
import { storeAuth } from "redux/slices/auth";
import { storeExchanges } from "redux/slices/exchanges";
import { storeExchangeId } from "redux/slices/appConfig";
// import components
import HCard from "components/HCard";
import HBox from "components/HBox";
import HImage from "components/HImage";
import Header from "components/Header";
// import routes from "routes";
import HContent from "components/HContent";

// social media
const socialMediaList = [
  {
    id: 1,
    label: "Linked In",
    url: "https://www.linkedin.com/in/winbee/",
    icon: icon.linkedIn,
  },
  {
    id: 2,
    label: "Twitter",
    url: "https://twitter.com/WinbeeTrading",
    icon: icon.twitter,
  },
  {
    id: 3,
    label: "Youtube",
    url: "https://www.youtube.com/channel/UCQ91a-FoKgZPi5nygmAtnFQ",
    icon: icon.youtube,
  },
];
const Index = ({ theme, routes, navbarHeight }) => {
  const href = useHref();
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(href);
  const [isHoverd, setIsHoverd] = useState(false);
  const { exchangeId, profileImage } = useSelector((state) => state.appConfig);
  useEffect(() => {
    if (href.lastIndexOf("/") === 0) {
      setActivePage(href);
    } else {
      const slice = href.slice(1);
      setActivePage(href.slice(0, slice.indexOf("/") + 1));
    }
  }, [href]);

  // make line of page active
  const activePageHandler = useCallback(
    (pageName) => {
      setActivePage(pageName);
    },
    [activePage]
  );
  // logout handler
  const logoutHandler = () => {
    authServices.logout();
    dispatch(storeAuth(null));
    dispatch(storeExchanges(null));
    dispatch(storeExchangeId(null));
  };
  return (
    <HCard
      className={`transition-all z-[1000] w-[80px] absolute top-full  left-0  text-secondry-font rounded-none overflow-hidden  border-0
        hover:w-[220px] hover:sm:w-[250px]`}
      color="secondry"
      style={{ height: `calc(100vh - ${navbarHeight}px)` }}
      onMouseEnter={() => setIsHoverd(true)}
      onMouseLeave={() => setIsHoverd(false)}
    >
      {/* <HBox className="flex items-center mb-8">
        <HImage
          src={isHoverd ? Logo : MinLogo}
          alt={"logo"}
          className={`${isHoverd ? "w-3/4 m-auto" : "w-full"}`}
        />
        {isHoverd && (
          <Header>
            <HContent as="span" className="text-2xl sm:text-3xl text-primary">
              WIN
            </HContent>
            BEE
          </Header>
        )}
      </HBox> */}
      <HBox className="flex flex-col justify-between h-full overflow-y-auto overflow-hidden scrollbar-hide">
        <ul className="relative max-h-[630px] overflow-y-auto scrollbar-hide">
          {routes
            .filter((route) => route.type.includes("collapse"))
            .map((route) => (
              <HContent as="li" className="text-center font-bold  mb-2 last:mb-0" key={route.key}>
                <Link
                  to={route.path}
                  onClick={() => activePageHandler(route.path)}
                  className={`flex items-center hover:bg-primary dark:hover:bg-primary-dark p-2 transition-all  rounded-sm ${
                    isHoverd ? "justify-start" : "justify-center"
                  } ${
                    (activePage || href) === route.path ? "bg-primary dark:bg-primary-dark" : ""
                  }`}
                >
                  <HContent as="span" className={`text-lg ${isHoverd ? "mr-2" : ""}`}>
                    {route.icon}
                  </HContent>
                  {isHoverd && (
                    <HContent className={`md:text-lg whitespace-nowrap`}>{route.name}</HContent>
                  )}
                </Link>
              </HContent>
            ))}
        </ul>
        <HBox>
          <Header as="h4" className={isHoverd ? "mb-2" : "hidden"}>
            Come join us:
          </Header>
          <ul className={`relative mb-2 ${isHoverd ? "flex justify-between flex-wrap" : ""}`}>
            {socialMediaList.map((social) => (
              <HContent as="li" className="text-center mb-2 last:mb-0" key={social.id}>
                <a
                  href={social.url}
                  className={`flex items-center hover:bg-primary  dark:hover:bg-primary-dark p-2  transition-all  rounded-sm ${
                    isHoverd ? "justify-start" : "justify-center"
                  } `}
                  target="_blank"
                >
                  <HContent as="span" className="text-lg">
                    {social.icon}
                  </HContent>
                </a>
              </HContent>
            ))}
          </ul>
          {isHoverd && <hr className="border-primary-b mb-2" />}
          <Link
            to="#"
            onClick={logoutHandler}
            className={`flex items-center hover:bg-primary dark:hover:bg-primary-dark p-2  transition-all  rounded-sm ${
              isHoverd ? "justify-start" : "justify-center"
            } `}
          >
            <HContent as="span" className={`text-lg ${isHoverd ? "mr-2" : ""}`}>
              {icon.logout}
            </HContent>
            {isHoverd && (
              <HContent as="span" className={` text-[1rem] md:text-lg whitespace-nowrap`}>
                Logout
              </HContent>
            )}
          </Link>
        </HBox>
      </HBox>
    </HCard>
  );
};

export default memo(Index);
