import React from "react";
import HBox from "components/HBox";

const Index = () => {
  window.$zoho = window.$zoho || {};
  window.$zoho.salesiq = window.$zoho.salesiq || {
    widgetcode: "77738717994a3f7897598f31fba2f72cdab8fc694b17db9e460ecbb4bf0b64dd",
    values: {},
    ready: function () {},
  };
  const d = document;
  let s;
  s = d.createElement("script");
  s.type = "text/javascript";
  s.id = "zsiqscript";
  s.defer = true;
  s.src = "https://salesiq.zoho.com/widget";
  let t;
  t = d.getElementsByTagName("script")[0];
  t.parentNode.insertBefore(s, t);
  return <HBox id="zsiqwidget" />;
};

export default Index;
