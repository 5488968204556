import HBox from "components/HBox";
import React, { memo } from "react";

const Index = React.forwardRef(
  ({ children, color = "default", border = true, className, ...rest }, ref) => {
    const cardColor = {
      default: "bg-white dark:bg-secondry-dark",
      transparent: "bg-transparent",
      secondry: "bg-secondry dark:bg-secondry-dark",
    };
    return (
      <HBox
        ref={ref}
        className={`${cardColor[color]} p-4 rounded-md shadow-md ${
          border ? "border border-primary-border dark:border-0" : ""
        }  ${className}`}
        {...rest}
      >
        {children}
      </HBox>
    );
  }
);

export default memo(Index);
