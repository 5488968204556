import React, { memo, useEffect, useRef, useState } from "react";
import Logo from "../../assets/images/logo/expand-logo-2.webp";
// import services
import exchangeServices from "utils/services/exchange.services";
import { useHref, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { icon } from "utils/icons";
import { storeExchanges } from "redux/slices/exchanges";
import { storeTheme, storeExchangeId } from "redux/slices/appConfig";
import { storeAuth } from "redux/slices/auth";
import checkMiddle from "utils/services/middle.services";

// import components
import HButton from "components/HButton";
import HCard from "components/HCard";
import Header from "components/Header";

import HBox from "components/HBox";
import { Menu, MenuButton, MenuList, MenuItem, Skeleton } from "@chakra-ui/react";
import HImage from "components/HImage";
import HContent from "components/HContent";
import CryptoTracker from "components/CryptoTracker";
import authServices from "utils/services/auth.services";
import SideBar from "components/SideBar";

// routes must not be hidden if user dont have exchanges
const routesMustNotBeHidden = ["/exchange", "/membership", "/heatmap", "/screener", "/profile"];
const Index = ({ theme, routes }) => {
  const href = useHref();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { exchangeId, profileImage } = useSelector((state) => state.appConfig);
  const navbarRef = useRef(null);
  const [navbarHeight, setNavbarHeight] = useState(64);
  const { data, isLoading } = useQuery(
    "get-exchange",
    () => {
      return exchangeServices.getExchanges().catch((error) => {
        authServices.logout();
        dispatch(storeAuth(null));
      });
    },
    {
      staleTime: 30000,
      cacheTime: 10000,
    }
  );

  //navbar resize handler
  const resizeHandler = () => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.clientHeight);
    }
  };
  // this for store exchange data
  useEffect(() => {
    if (data?.data.length > 0) {
      dispatch(storeExchanges(data.data));
      dispatch(storeExchangeId(data.data[0].id));
    }
  }, [data]);
  useEffect(() => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.clientHeight);
    }
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, [navbarRef.current]);
  // if user dont have exchange so go to exchange page
  if (
    data?.data.length === 0 &&
    !isLoading &&
    !routesMustNotBeHidden.some((route) => href.includes(route)) &&
    !user.roles.includes("ROLE_ADMIN")
  ) {
    location.href = location.origin + "/exchange";
  }

  // if use have exchange but membership expired go to membership page.
  if (
    data?.data.length > 0 &&
    !isLoading &&
    !href.includes("/exchange") &&
    !href.includes("/membership") &&
    !user.roles.includes("ROLE_ADMIN")
  ) {
    checkMiddle(navigate);
  }
  // change account exchange
  const changeExchangeHandler = (id) => {
    dispatch(storeExchangeId(id));
  };
  // change to dark/light mode of app
  const changeThemeHandler = () => {
    if (theme === "light") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("winbee-theme", "dark");
      dispatch(storeTheme("dark"));
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("winbee-theme", "light");
      dispatch(storeTheme("light"));
    }
  };
  return (
    <HCard
      ref={navbarRef}
      className={`rounded-none top-0 transition-all border-0 fixed z-50 w-full`}
    >
      <HBox className="flex items-center justify-between ">
        <HBox className="flex items-center">
          <HBox className="flex items-center mr-4">
            <HImage src={Logo} alt={"logo"} className="w-[40px] md:w-[50px]" />
            <Header>
              <HContent as="span" className="text-lg sm:text-3xl text-primary">
                WIN
              </HContent>
              <HContent as="span" className="text-lg sm:text-3xl">
                BEE
              </HContent>
            </Header>
          </HBox>
        </HBox>
        <HBox className="flex items-center">
          {/* <CryptoTracker /> */}
          <HButton
            onClick={changeThemeHandler}
            varient="transparent"
            color={theme === "dark" ? "light" : "secondry"}
            className="mr-2 dark:hover:text-primary-dark hover:text-primary"
          >
            {theme === "dark" ? icon.dark : icon.light}
          </HButton>
          <Menu>
            <MenuButton as={"button"}>
              <HBox className="flex items-center">
                <HBox className="w-[30px] mr-2 text-3xl text-secondry dark:text-light-font ">
                  {profileImage ? (
                    <HImage
                      src={profileImage}
                      alt="user-avatar"
                      className="w-full h-[30px] rounded-full"
                    />
                  ) : (
                    icon.user
                  )}
                </HBox>
                <HBox className="text-start">
                  {user ? (
                    <HContent as="span" className="block">
                      {user.username?.slice(0, 15)}
                    </HContent>
                  ) : (
                    <Skeleton w={10} h={1} />
                  )}
                  {!isLoading ? (
                    <HContent as="span">
                      {data?.data?.find((exchange) => exchange.id === exchangeId)?.exchangename}
                    </HContent>
                  ) : (
                    <Skeleton mt={2} w={10} h={1} />
                  )}
                </HBox>
              </HBox>
            </MenuButton>
            <MenuList
              bg={`${theme === "dark" ? "var(--d-secondry-color)" : "var(--secondry-font)"}`}
              border={`${theme === "dark" ? "var(--d-primary-color)" : ""}`}
              boxShadow={`${theme === "dark" ? "0 0 3px 0 var(--d-primary-border)" : "xl"}`}
            >
              <HBox className="max-h-[200px] overflow-y-auto scrollbar-hide">
                {isLoading ? (
                  <>
                    <Skeleton w="full" h={3} mb={4} />
                    <Skeleton w="full" h={3} mb={4} />
                    <Skeleton w="full" h={3} />
                  </>
                ) : data?.data.length > 0 ? (
                  data?.data.map((exchange) => (
                    <MenuItem
                      key={exchange.id}
                      onClick={() => changeExchangeHandler(exchange.id)}
                      bg="transparent"
                      _hover={{
                        bg: theme === "dark" ? "var(--d-primary-color)" : "var(--primary-color)",
                        color: "var(--secondry-font)",
                      }}
                      fontSize={{ base: "0.875rem", md: "1rem" }}
                    >
                      {exchange.exchangename}
                    </MenuItem>
                  ))
                ) : (
                  <HContent className="p-2">You dont have any exchange account</HContent>
                )}
              </HBox>
            </MenuList>
          </Menu>
        </HBox>
      </HBox>
      <SideBar theme={theme} routes={routes} navbarHeight={navbarHeight} />
    </HCard>
  );
};

export default memo(Index);
