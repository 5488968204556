import axios from "axios";
import { authHeader } from "./auth-header";
import config from "./config";

const API_URL = config.endpoint() + "/api/profile/";

const getProfile = async () => {
  const response = await axios.get(API_URL, { headers: authHeader() });
  const data = response.data;
  return data;
};

const setProfile = async (profile) => {
  return await axios.post(API_URL, profile, { headers: authHeader() });
};

const getIncomes = async () => {
  const { data } = await axios.get(`${API_URL}incomes`, { headers: authHeader() });
  return data;
};

export default {
  getProfile,
  setProfile,
  getIncomes,
};
