import axios from "axios";
import { authHeader } from "./auth-header";
import config from "./config";

const API_URL = config.endpoint() + "/api/swap/";

const doTrade = (exchangeId, side, symbol, orderType, qty, price = undefined) => {
  let params = `exchangeId=${exchangeId}&side=${side}&symbol=${symbol}&orderType=${orderType}&qty=${qty}`;
  if (price) {
    params = params + `&price=${price}`;
  }
  return axios.get(API_URL + "trade?" + params, { headers: authHeader() });
};
// get token price
const getTokenPrice = (exchangeId, symbol) => {
  let params = `exchangeId=${exchangeId}&symbol=${symbol}`;
  return axios.get(API_URL + "gettokenprice?" + params, { headers: authHeader() });
};

// get price of eth and btc
const getTokenPriceTracker = () => {
  return axios.get(
    "https://min-api.cryptocompare.com/data/pricemultifull?fsyms=BTC,ETH&tsyms=USDT"
  );
};

// get history orders
const getHistoryOrders = async (symbol, userId) => {
  return await axios.get(API_URL + "history?symbol=" + symbol + "&userId=" + userId, {
    headers: authHeader(),
  });
};

export default {
  getTokenPrice,
  getTokenPriceTracker,
  doTrade,
  getHistoryOrders,
};
