import { createSlice } from "@reduxjs/toolkit";

const exchangesSlice = createSlice({
  name: "exchanges",
  initialState: {
    exchanges: [],
  },
  reducers: {
    storeExchanges: (state, action) => {
      const { payload } = action;
      state.exchanges = payload;
    },
  },
});

export const { storeExchanges } = exchangesSlice.actions;
export default exchangesSlice.reducer;
