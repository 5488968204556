import { storeProfileImage } from "redux/slices/appConfig";
import profileServices from "./services/profile.services";

export const gettimestringOfDate = (timestamp) => {
  let date = new Date(timestamp).toISOString();
  date = date.replace("Z", "");
  date = date.replace("T", " ");
  date = date.substring(0, 10);
  return date;
};

export const getSymbolPrice = async (symbol,currency) => {
  try {
    console.log(`https://min-api.cryptocompare.com/data/price?fsym=${symbol}&tsyms=${currency}`)
		const response = await fetch(`https://min-api.cryptocompare.com/data/price?fsym=${symbol}&tsyms=${currency}`);
		return response.json();
	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}

// https://min-api.cryptocompare.com/data/price?fsym=BTC&tsyms=USD
export const getProfileImage = async (dispatch) => {
  const response = await profileServices.getProfile();
  if (!response.message) {
    dispatch(storeProfileImage(response.user.profileImage));
  }
};
