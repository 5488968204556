import {
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineSetting,
  AiOutlineFileText,
  AiOutlineStop,
  AiOutlineReload,
  AiFillDashboard,
  AiFillControl,
  AiFillInteraction,
  AiOutlineRise,
} from "react-icons/ai";
import {
  FaSpinner,
  FaMicrochip,
  FaLinkedinIn,
  FaBtc,
  FaUser,
  FaLink,
  FaYoutube,
  FaTwitter,
  FaFacebookF,
  FaMoon,
  FaUserCircle,
  FaSignOutAlt,
  FaRobot,
  FaExchangeAlt,
  FaBuromobelexperte,
  FaMicrosoft,
  FaWatchmanMonitoring,
} from "react-icons/fa";
import { FaSackDollar } from "react-icons/fa6";
import { HiMenuAlt2, HiUserGroup, HiChartBar } from "react-icons/hi";
import {
  MdOutlineSell,
  MdOutlineCancel,
  MdOutlineArrowDropDownCircle,
  MdOutlineCardMembership,
} from "react-icons/md";
import {
  FiPlus,
  FiTrash2,
  FiSearch,
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiEdit,
  FiCopy,
} from "react-icons/fi";
import { BsSunFill } from "react-icons/bs";
import {
  IoLogoUsd,
  IoCloudDownloadOutline,
  IoWarningOutline,
  IoBriefcaseOutline,
  IoCopy,
  IoGrid,
} from "react-icons/io5";
import { TiArrowSortedUp, TiArrowSortedDown, TiArrowUnsorted } from "react-icons/ti";
import { GoServer } from "react-icons/go";
import { BsCoin } from "react-icons/bs";

export const icon = {
  dashboard: <AiFillDashboard />,
  control: <AiFillControl />,
  stock: <HiChartBar />,
  interactior: <AiFillInteraction />,
  robot: <FaRobot />,
  profile: <FaUser />,
  grid: <IoGrid />,
  signal: <FaMicrochip />,
  exchange: <FaExchangeAlt />,
  setting: <AiOutlineSetting />,
  eye: <AiFillEye />,
  eyeInvisible: <AiFillEyeInvisible />,
  spinner: <FaSpinner />,
  menu: <HiMenuAlt2 />,
  logout: <FaSignOutAlt />,
  linkedIn: <FaLinkedinIn />,
  twitter: <FaTwitter />,
  youtube: <FaYoutube />,
  user: <FaUserCircle />,
  dark: <FaMoon />,
  light: <BsSunFill />,
  btc: <FaBtc />,
  usd: <FaSackDollar />,
  accounts: <HiUserGroup />,
  sortUp: <TiArrowSortedUp />,
  sortDown: <TiArrowSortedDown />,
  unsorted: <TiArrowUnsorted />,
  search: <FiSearch />,
  chevronLeft: <FiChevronLeft />,
  chevronRight: <FiChevronRight />,
  chevronDown: <FiChevronDown />,
  plus: <FiPlus />,
  download: <IoCloudDownloadOutline />,
  dropDown: <MdOutlineArrowDropDownCircle />,
  stop: <AiOutlineStop />,
  order: <IoBriefcaseOutline />,
  details: <AiOutlineFileText />,
  sell: <MdOutlineSell />,
  cancel: <MdOutlineCancel />,
  reload: <AiOutlineReload />,
  warning: <IoWarningOutline />,
  trash: <FiTrash2 />,
  edit: <FiEdit />,
  link: <FaLink />,
  copy: <IoCopy />,
  facebook: <FaFacebookF />,
  limit: <AiOutlineRise />,
  proxy: <GoServer />,
  membership: <MdOutlineCardMembership />,
  withdrawal: <BsCoin />,
  microsoft: <FaMicrosoft />,
  watchmanMonitoring: <FaWatchmanMonitoring />,
};
