import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Provider } from "react-redux";

import { store } from "redux/store";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();
const container = document.getElementById("root");
const appRoot = createRoot(container);
appRoot.render(
  <ChakraProvider>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <App tab="home" />
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </ChakraProvider>
);
