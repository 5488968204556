const isAdmin = (userInfo) => {
  for (let i = 0; i < userInfo.roles.length; i++) {
    if (userInfo.roles[i] == "ROLE_ADMIN") return true;
  }
  return false;
};

const checkMiddle = (navigate) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (isAdmin(user)) return;
  if (!user.expire || user.expire < Date.now() / 1000) {
    navigate("/membership");
  }
};
export default checkMiddle;
