import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
  name: "configSlice",
  initialState: {
    theme: "light",

    exchangeId: null,
    profileImage: null,
  },
  reducers: {
    storeTheme: (state, action) => {
      const { payload } = action;
      state.theme = payload;
    },
    storeExchangeId: (state, action) => {
      const { payload } = action;
      state.exchangeId = payload;
    },
    storeProfileImage: (state, action) => {
      const { payload } = action;
      state.profileImage = payload;
    },
  },
});

export const { storeTheme, storeExchangeId, storeProfileImage } = configSlice.actions;
export default configSlice.reducer;
