const apiport = 443;
const endpoint = () => {
  //return ("http://"+window.location.hostname+":"+config.APIPORT);
  return "https://api.winbee.io";
  // return "http://localhost:4433";
  // return ("https://app.winbee.io:4433");
  // return ("https://beta.winbee.io");
};

const webSocketUrl = () => {
  return "wss://ws.winbee.io";
};
export default {
  APIPORT: apiport,
  endpoint: endpoint,
  webSocketUrl: webSocketUrl,
};
