import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
  },
  reducers: {
    storeAuth: (state, action) => {
      const { payload } = action;
      state.user = payload;
    },
  },
});

export const { storeAuth } = authSlice.actions;
export default authSlice.reducer;
