import { createSlice } from "@reduxjs/toolkit";

const stateManageSlice = createSlice({
  name: "stateManage",
  initialState: {
    chartData: [],
  },
  reducers: {
    chartData: (state, action) => {
      const { payload } = action;
      state.chartData = payload;
    },
  },
});

export const { chartData } = stateManageSlice.actions;
export default stateManageSlice.reducer;
