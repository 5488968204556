import axios from "axios";
import { authHeader } from "./auth-header";
import config from "./config";

// api end point
const API_URL = config.endpoint() + "/api/exchange/";

const getExchange = (exchangeId) => {
  return axios.get(API_URL + "detail?id=" + exchangeId, { headers: authHeader() });
};
const deleteExchange = (exchangeId) => {
  return axios.get(API_URL + "delete?id=" + exchangeId, { headers: authHeader() });
};

const getExchanges = () => {
  return axios.get(API_URL + "myexchange", { headers: authHeader() });
};
const setExchange = (name, apikey, secret, exchangeId, testnet) => {
  if (exchangeId == "Create") {
    //update
    return axios.post(
      API_URL + "create",
      {
        name,
        apikey,
        secret,
      },
      { headers: authHeader() }
    );
  } else {
    //create
    return axios.post(
      API_URL + "update?id=" + exchangeId,
      {
        name,
        apikey,
        secret,
      },
      { headers: authHeader() }
    );
  }
};

const recoverExchange = (apiKey) => {
  return axios.post(
    config.endpoint() + "/api/exchange/recover",
    {
      apiKey,
    },
    { headers: authHeader() }
  );
};

const getExchangesByUserEmail = (email) => {
  return axios.get(API_URL + "getExchangesByEmail?email=" + email, { headers: authHeader() });
};

export default {
  getExchange,
  setExchange,
  getExchanges,
  deleteExchange,
  recoverExchange,
  getExchangesByUserEmail,
};
