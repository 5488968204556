import axios from "axios";
import config from "./config";

const API_URL = config.endpoint() + "/api/auth/";

// send code for login
const sendCode = (email) => {
  return axios.post(API_URL + "sendcode", { email });
};
// login to app
const login = (email, password, code) => {
  return axios.post(API_URL + "signin", {
    email,
    password,
    code,
  });
};
// register
const register = (username, email, password, ref) => {
  if (ref) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
      ref,
    });
  } else {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
    });
  }
};
// visitor
const visit = (refId) => {
  return axios.get(API_URL + "visit?refid=" + refId).then((response) => {
    return response.data;
  });
};
// send forget password code
const setForgot = (email) => {
  return axios.post(API_URL + "setforgot", {
    email,
  });
};
// apply reset password
const resetPassword = (email, passwordcode, password) => {
  return axios.post(API_URL + "resetpassword", {
    email,
    passwordcode,
    password,
  });
};
// email verify
const emailVerify = (token) => {
  return axios.post(API_URL + "verify", {
    token,
  });
};

// logout
const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("exchange");
};
export default {
  sendCode,
  login,
  register,
  visit,
  setForgot,
  resetPassword,
  logout,
  emailVerify,
};
